import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { OverallRatingComponent } from './components/overall-rating/overall-rating.component';
import { RouterModule } from '@angular/router';
import { MatchesComponent } from './components/matches/matches.component';
import { ProgressComponent } from './components/progress/progress.component';
import { FinalRatingComponent } from './components/final-rating/final-rating.component';
import { FinalOverviewComponent } from './components/final-overview/final-overview.component';
import { FeedbackAwaitingComponent } from './components/feedback-awaiting/feedback-awaiting.component';
import { WorkshopsRatingComponent } from './components/workshops-rating/workshops-rating.component';

export const MATCHES = 'matches';
export const OVERALLRATING = 'overall-rating';
export const PROGRESSRATING = 'progress-rating';
export const FINALRATING = 'final-rating';
export const FINALOVERVIEW = 'final-overview';
export const FBKSAWAITING = 'fbks-awaiting';
export const LSWORKSHOPS = 'workshops-rating';

@NgModule({
  declarations: [
    OverallRatingComponent,
    MatchesComponent,
    ProgressComponent,
    FinalRatingComponent,
    FinalOverviewComponent,
    FeedbackAwaitingComponent,
    WorkshopsRatingComponent,
  ],
  imports: [
    RouterModule.forChild([
      {
        path: '',
        children: [
          { path: MATCHES, component: MatchesComponent },
          { path: OVERALLRATING, component: OverallRatingComponent },
          { path: PROGRESSRATING, component: ProgressComponent },
          { path: FINALRATING, component: FinalRatingComponent },
          { path: FINALOVERVIEW, component: FinalOverviewComponent },
          { path: FBKSAWAITING, component: FeedbackAwaitingComponent },
          { path: LSWORKSHOPS, component: WorkshopsRatingComponent },
        ],
      },
    ]),
    CommonModule,
  ],
})
export class ReportsModule {}
